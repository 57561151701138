/* eslint-disable import/no-unresolved */
import React, { useRef } from 'react';
import SwiperCore, { A11y, Navigation } from 'swiper';
import { Swiper } from 'swiper/react';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import H2 from './H2';

SwiperCore.use([Navigation, A11y]);

function Slider({ className, children, title, spaceBetween, slidesPerView, border, titlePaddingClass, marginTop }) {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  return (
    <div
      className={`${className} mr-auto ${marginTop ? null : "mt-8 lg:mt-16"} ${border ? 'border-t border-solid border-dark-50 py-8 lg:py-16' : 'border-none'
        }`}
    >
      {title ? <div
        className={`inline-flex w-auto items-center justify-between ${titlePaddingClass || 'pb-8'
          }  lg:w-full xl:max-w-7xl text-dark-800`}
      >
        <H2 text={title} className="font-nature-meet-standard text-dark-800" />
        <div className="hidden gap-6 lg:flex">
          <button
            id="previousButton"
            ref={navigationPrevRef}
            type="button"
            className={`slider__button slider__button-prev font-medium ${className ? `${className}__button ${className}__button-prev` : ''
              } flex h-8 w-8 items-center justify-center rounded-full bg-dark-50
          `}
          >
            <FontAwesomeIcon
              icon={faAngleLeft}
              className="h-[16.8px]  w-3 text-xs leading-[16.8px] text-dark-900"
            />
          </button>
          <button
            type="button"
            id="nextButton"
            ref={navigationNextRef}
            className={`slider__button slider__button-next font-medium ${className ? `${className}__button ${className}__button-next` : ''
              } flex h-8 w-8 items-center justify-center rounded-full bg-dark-50
          `}
          >
            <FontAwesomeIcon
              icon={faAngleRight}
              className="h-[16.8px]  w-3 text-xs leading-[16.8px] text-dark-900"
            />
          </button>
        </div>
      </div> : null}
      <Swiper
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        navigation={{
          nextEl: navigationNextRef.current,
          prevEl: navigationPrevRef.current,
        }}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.nextEl = navigationNextRef.current;
          swiper.params.navigation.prevEl = navigationPrevRef.current;
        }}
      >
        {children}
      </Swiper>
    </div>
  );
}

Slider.defaultProps = {
  border: true,
};

export default Slider;
