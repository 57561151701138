import React from 'react';
import RichTextParser from './RichTextParser';

function SubHeadline({ text, className }) {
  return (
      <h6 className={`${className} font-sans md:text-xl text-[18px] leading[25.2px] leading-[1.4]`}><RichTextParser>{text}</RichTextParser></h6>
  );
}

export default SubHeadline;
