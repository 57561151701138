/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { faCircleInfo, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import H4 from './H4';
import Paragraph from './Paragraph';

function Alert({
  title, text, link, measures, className, alertType, id, spacing
}) {
  const AlertContent = (
    <div className={`${className}, ${!spacing ? 'mt-14 mb-24' : 'mb-14'} relative rounded bg-primary-extraLight   mx-auto xl:max-w-[834px]`} key={id}>
      <div className="mx-4 flex pt-8 pb-8 md:mx-8 flex-col">
        <div className="inline-block font-secondary">
          <div className="flex items-baseline gap-3">
            <div>
              <span>
                <FontAwesomeIcon
                  fontWeight={600}
                  icon={faCircleInfo}
                  className="text-[18px] text-alert-main"
                />
              </span>
            </div>
            <div>
              <H4 className="text-alert-main" text={title} />
            </div>
          </div>
          {text ? (
            <Paragraph className="pt-2 font-normal leading-normal text-alert-main" text={text} />
          ) : null}
          {link ? (
            <div className="pt-4">
              <a
                href={link.url}
                className="cursor-pointer text-base font-medium leading-none text-dark-800 underline"
              >
                {link.link_text}
              </a>
            </div>
          ) : null}
        </div>
        {measures
          ? measures.map((measure) => (
            <div key={measure.text} className="flex gap-3 pt-4 first:pt-2">
              <span>
                <FontAwesomeIcon size="1x" icon={faCheck} className="text-alert-main" />
              </span>
              <Paragraph
                className="font-normal leading-normal text-alert-main"
                text={measure.text}
              />
            </div>
          ))
          : null}
      </div>
    </div>
  );

  // // TODO: Maybe look into the use of 'absolute positioning' in the app to avoid this hack 🤷🏾‍♂️
  if (alertType && alertType === 'primary') {
    return <div className="mb-[96px]">{AlertContent}</div>;
  }

  return AlertContent;
}

export default Alert;
