import React from 'react';

function H3({ text, className }) {
  return (
    <div>
      <h3 className={`${className} font-poppins font-semibold leading-[26px] lg:leading-[28.6px] text-xl lg:text-[22px]`}>{text}</h3>
    </div>
  );
}

export default H3;
