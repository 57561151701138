import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faClockFive } from '@fortawesome/pro-regular-svg-icons';
import H5 from './H5';
import Paragraph from './Paragraph';
import Link from 'next/link';
import NMImage from './shared/NMImage';

function Card({ card, image, alt, className }) {
  return (
    <div className={` ${className} w-full self-start lg:max-w-[394px]`}>
      <Link href={card.url ? card.url : "/"} className="cursor-pointer">
        <a>
          <div className="relative pt-[56.25%] w-full">
            {image && (
              <NMImage
                layout="fill"
                src={image}
                className="max-h-[239.576px] w-full rounded-xl object-cover object-center"
                alt={alt}
              />
            )}
          </div>
          <div className="pt-3">
            <H5 className=" font-semibold text-dark-800" text={card.text} />
            {card?.location ? (
              <div className="flex items-center gap-x-2 pt-3 text-dark-600">
                <FontAwesomeIcon
                  icon={faLocationDot}
                  className="h-[16.8px] w-3 text-xs leading-[16.8px] text-dark-600"
                />
                <Paragraph className="m-0" fontSizeClass="text-base" text={card.location} />
              </div>
            ) : null}
            {card.duration ? (
              <div className="flex items-center gap-x-2 pt-2 text-dark-600">
                <FontAwesomeIcon
                  icon={faClockFive}
                  className="h-[16.8px] w-3 text-xs leading-[16.8px] text-dark-600"
                />
                <Paragraph className="m-0" fontSizeClass="text-base" text={card.duration} />
              </div>
            ) : null}
            {card.price ? (
              <p className="pt-3 font-sans text-dark-800">
                <span className="text-lg leading-[25.2px] font-semibold">{card.price}</span>
                <span className="text-lg font-normal leading-[22.4px]"> / person</span>
              </p>
            ) : null}
          </div>
        </a>
      </Link>
    </div>
  );
}

export default Card;
