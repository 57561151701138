import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Button from '../Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faXmark, faAngleLeft } from '@fortawesome/pro-regular-svg-icons';
// import Checkbox from './Checkbox';

function Modal({ showModal, setShowModal, children, title, notRounded, onSubmit, onCancel, noButtons }) {
  return (
    <>
      <Transition appear={true} show={showModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[999]"
          open={showModal}
          onClose={() => setShowModal(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-400"
            enterFrom="opacity-0 "
            enterTo="opacity-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 h-auto bg-black-900 bg-opacity-30" />
          </Transition.Child>

          <div
            className={`${notRounded ? 'inset-0' : 'inset-x-0 top-[90px] md:top-10'
              } fixed  h-full  overflow-y-auto`}
          >
            <div className="flex min-h-full  justify-center text-center md:items-center md:p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-500"
                enterFrom="opacity-0 scale-95 translate-y-full"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={`${notRounded
                    ? 'max-h-full w-screen pb-16 md:pb-0 lg:w-[768px]'
                    : 'w-[768px] rounded-[32px]'
                    } flex h-auto transform flex-col justify-between overflow-hidden overflow-y-scroll  rounded-[32px] bg-white px-5 pt-6 text-left align-middle shadow-xl transition-all lg:h-fit lg:max-h-[748px] lg:min-h-auto lg:px-8`}
                >
                  {title && (
                    <Dialog.Title
                      as="h5"
                      className="flex flex-none gap-4 pb-3 font-poppins text-lg font-semibold leading-[23.4px] text-dark-800"
                    >
                      <div
                        onClick={() => setShowModal(false)}
                        className="mt-px cursor-pointer text-dark-800"
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </div>
                      {title}
                    </Dialog.Title>
                  )}
                  {notRounded ? (
                    <div className=" block cursor-pointer" onClick={() => setShowModal(false)}>
                      <div className="text-dark-800 md:hidden">
                        <FontAwesomeIcon icon={faAngleLeft} size="2x" className="text-dark-800" />{' '}
                      </div>
                      <div className="hidden text-dark-800 md:block">
                        <FontAwesomeIcon icon={faXmark} size="2x" />{' '}
                      </div>
                    </div>
                  ) : null}
                  <div className={`${notRounded ? null : null} flex-1 pt-8`}>{children}</div>

                  {!noButtons ? (
                    <div className="flex w-full justify-end gap-8 px-0 py-4">
                      <Button
                        type="button"
                        className="inline-flex h-[44px] w-[73px] cursor-pointer items-center justify-center gap-2 font-poppins text-base font-medium leading-[16px] text-dark-800"
                        onClick={() => {
                          setShowModal(false);
                          if (!onCancel) return;
                          onCancel();
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} className="mb-1 text-dark-800" />
                        Delete
                      </Button>
                      <Button
                        type="button"
                        className="inline-flex h-[44px] w-[150px] cursor-pointer items-center justify-center rounded-full bg-dark-900 font-poppins text-base  font-medium leading-[16px] text-white"
                        onClick={() => {
                          setShowModal(false);
                          if (!onSubmit) return;
                          onSubmit();
                        }}
                      >
                        Show results
                      </Button>
                    </div>
                  ) : null}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
export default Modal;
