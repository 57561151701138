import getImageData from '../helpers/ImageHelper';
import Card from './Card';
import { formatPrice } from '../utils/formatPrice';
import { FormatDuration } from '../utils/dateFormater';

export default function TripCard({ trip, container_full }) {
  const image = getImageData(trip?.attributes?.preview_image);
  const getAttributes = () => {
    return {
      url: `/trips/${trip?.attributes?.slug}`,
      text: trip?.attributes?.title,
      duration: `${FormatDuration(trip?.attributes?.duration) || ''} (${trip?.attributes?.distance
        } km)`,
      price: formatPrice(trip?.attributes?.display_price),
      location: `${trip?.attributes?.start_region}, ${trip?.attributes?.start_state}`,
    };
  };
  return (
    <Card
      card={getAttributes()}
      image={image.src}
      alt={image.alt}
      className={`${container_full ? 'max-w-full' : 'max-w-[326px]'} text-dark-800`}
    />
  );
}
