import React from 'react';

function H1({ text, className }) {
  return (
    <div>
      <h1 className={`${className} font-nature-meet-standard md:text-[72px] text-[40px] leading-none`}>{text}</h1>
    </div>
  );
}

export default H1;
