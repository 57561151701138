// format date to Day, Mon.DD and time to HH:MM 24hrs format Germany
export const FormatDate = (date) => {
  const dateObj = new Date(date);
  const day = dateObj.getDay();
  const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const dayName = dayNames[day];
  const monthName = monthNames[dateObj.getMonth()];
  const dayNum = dateObj.getDate();
  const year = dateObj.getFullYear();
  const hours = dateObj.getHours(); // 0-23
  const minutes = dateObj.getMinutes() > 9 ? dateObj.getMinutes() : `0${dateObj.getMinutes()}`;
  const formattedTime = `${hours}:${minutes}`;
  const formattedDate = `${dayName}, ${monthName} ${dayNum}`;
  const checkoutFormattedDate = `${dayName}, ${monthName} ${dayNum}`;
  return { dayName, monthName, dayNum, year, formattedTime, formattedDate, checkoutFormattedDate };
};

export const FormatDuration = (duration) => {
  let output = '';
  const days = Number(duration?.days);
  const hours = Number(duration?.hours);
  const minutes = Number(duration?.minutes);
  if (days > 0) {
    output += `${days} day${days !== 1 ? 's' : ''} `;
  }

  if (hours > 0) {
    output += `${hours} hour${hours != 1 ? 's' : ''} `;
  }

  if (minutes > 0) {
    output += `${minutes} minute${minutes != 1 ? 's' : ''} `;
  }

  return output;
};
