// @ts-nocheck
import { useState } from 'react';
import getImageData from '../helpers/ImageHelper';
import Modal from './ModalComponent/Modal';
import H2 from './H2';
import H4 from './H4';
import SubHeadline from './SubHeadline';
import NMImage from './shared/NMImage';

function TopicCard({ item, className, modal, modalData, onClick }) {
  const image = getImageData(item?.attributes?.image || item?.attributes?.preview_image).src;
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <div id="destinations"
        className={`${className} cursor-pointer`}
        onClick={() => {
          modal === 'true' ? setShowModal(true) : null;
          onClick && onClick(item);
        }}
      >
        <div className="relative">
          <div className="absolute top-0 z-20 h-full w-full rounded-xl bg-black-900 opacity-40" />
          {image ? (
            <NMImage
              width={247}
              height={186}
              layout="responsive"
              src={image}
              alt=""
              className="relative h-[117px] w-full rounded-xl object-cover md:h-[186px]"
            />
          ) : (
            <div className="relative h-[117px] w-full bg-black-400 md:h-[186px] rounded-xl" />
          )}
        </div>
        <div className="absolute top-0 px-7 z-50 flex h-full w-full items-center justify-center">
          <p className="text-center font-poppins text-lg font-semibold leading-[130%] text-white md:text-xl">
            {item?.attributes?.name || item?.attributes?.start_region || item?.attributes?.title}
          </p>
        </div>
      </div>
      {modalData ? (
        <Modal showModal={showModal} setShowModal={setShowModal} notRounded noButtons>
          <H2 text="Highlights of the trip" className="text-dark-800" />
          {modalData?.map((item) => (
            <div key={item.id}>
              <H4 text={item?.attributes?.title} className="pt-8 text-dark-800" />
              <SubHeadline text={item?.attributes?.description} className="pt-4 text-dark-800" />
              <div className="w-full border-b border-solid border-dark-50 pt-6 pb-8">
                {image && (
                  <NMImage
                    width={704}
                    height={396}
                    layout="responsive"
                    src={
                      getImageData(item?.attributes?.image || item?.attributes?.preview_image).src
                    }
                    alt=""
                    className="relative h-full w-full object-cover"
                  />
                )}
              </div>
            </div>
          ))}
        </Modal>
      ) : null}
    </>
  );
}

export default TopicCard;
