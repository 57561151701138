import React from 'react';

function H5({ text, className }) {
  return (
    <div>
      <h5 className={`${className} font-poppins text-base lg:text-lg md:leading[23.4px] leading-[20.8px] font-semibold`}>{text}</h5>
    </div>
  );
}

export default H5;
