// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { SwiperSlide } from 'swiper/react';
import Slider from './Slider';
import H2 from './H2';
import TopicCard from './TopicCard';
import { useRouter } from "next/router";

function ActionButton({ button, onClick, alignment, }) {
  return button ? (
    <button
      className={`${alignment ? 'border border-solid border-dark-900 bg-transparent' : 'bg-secondary-main'
        } text-normal hover:bg-secondary-main-light rounded-full px-6 py-3 font-poppins font-medium leading-none text-dark-800 focus:outline-none`}
      onClick={onClick}
      type="button"
    >
      {button?.text}
    </button>
  ) : null;
}

function TopicsCardGrid({
  alignment,
  data,
  className,
  button,
  title,
  subtitle,
  grid,
  gap,
  border,
  subHeading,
  popup,
  onClick,
  display,
  pagePadding,
  pageTop,
  mobileAlign,
  allVisible
}) {
  const gapSize = gap || 4;
  const gridCount = grid || 3;
  const [slices, setSlices] = useState(6);
  const [visibleItems, setVisibleItems] = useState(data?.slice(0, slices));
  const router = useRouter();

  useEffect(() => {
    if (allVisible) {
      setSlices(data?.length);
      setVisibleItems(data);
    }
    else if (window.innerWidth < 768) {
      setVisibleItems(data?.slice(0, 6));
      setSlices(6);
    } else {
      setVisibleItems(data?.slice(0, 6));
      setSlices(6);
    }
  }, [data]);

  const handleClick = () => {
    if (button.url) {
      router.push(button.url);
    }
    setVisibleItems(data?.slice(0, visibleItems.length + 3))
  };

  return (
    <>
      <div className={`${pagePadding ? 'pb-16 lg:pb-24' : null}, ${pageTop ? 'pt-14 lg:pt-0' : null}  w-full`}>
        <div
          className={[
            alignment ? 'hidden lg:pt-0 lg:grid lg:mt-16' : 'lg:mt-24',
            border && alignment ? 'border-t border-solid border-dark-50' : 'border-none',
            className,
            ` ${mobileAlign ? 'mt-16' : 'mt-12'} grid text-dark-800`,
          ].join(' ')}
        >
          <H2
            className={`${alignment || mobileAlign ? 'text-left ' : 'text-center'
              } w-[87.2vw] text-dark-800 lg:w-full lg:text-[44px] ${border && alignment ? 'pt-16 lg:pb-8' : 'lg:pb-12'} ${!subtitle ? 'pb-6' : ''} `}
            text={title}
          />

          {subtitle && (
            <div
              className={`${subHeading ? `hidden` : 'block'
                } text-black_lighter font-accent max-w-[52.25rem] py-8 text-center text-base leading-normal md:text-xl`}
            >
              {subtitle}
            </div>
          )}
          <div
            className={`${alignment
              ? `mr-auto w-full gap-4 lg:gap-${gapSize}`
              : 'mx-auto w-full max-w-[830px] justify-items-center gap-4 lg:gap-12'
              } relative grid grid-cols-2 lg:grid-cols-${gridCount}`}
          >
            {visibleItems?.map((item) => (
              <TopicCard modalData={visibleItems} modal={popup} key={item?.id} item={item} className="relative w-full" onClick={onClick} />
            ))}
          </div>
          <div className={`${alignment ? 'mr-auto' : 'mx-auto'} ${display ? 'flex' : 'hidden'} lg:p-12 pt-6 text-base font-medium`}>
            <ActionButton
              button={button}
              onClick={handleClick}
              alignment={alignment}
            />
          </div>
        </div>
        {alignment ? (
          <>
            <Slider
              slidesPerView="auto"
              spaceBetween={10}
              title={title}
              className="max-w-[730px] lg:hidden text-dark-800"
              border={false}
            >
              {visibleItems?.map((item) => (
                <SwiperSlide key={item.id}>
                  <TopicCard className="relative w-[213px]" modal={popup} item={item} modalData={data} onClick={onClick} />
                </SwiperSlide>
              ))}
            </Slider>
            <div className={`${alignment ? 'mr-auto' : 'mx-auto'} ${display ? 'flex' : 'hidden'}  pt-12 lg:hidden`}>
              <ActionButton
                button={button}
                onClick={handleClick}
                alignment={alignment}
              />
            </div>
          </>
        ) : null}
      </div>
    </>
  );
}

TopicsCardGrid.defaultProps = {
  border: true,
};
export default TopicsCardGrid;
