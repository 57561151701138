export function stripHTML(myString) {
  return myString.replace(/(<([^>]+)>)/gi, '');
}

export function paramToArray(items) {
  return Array.isArray(items) ? items : [items];
}

export function shuffleArray(items) {
  if (!items || !Array.isArray(items) || !items.length) return [];

  return [...items]
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);
}
