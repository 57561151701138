import React from 'react';

function H4({ text, className }) {
  return (
    <div>
      <h4 className={`${className} font-poppins font-semibold leading-[1.3] text-lg md:text-xl`}>{text}</h4>
    </div>
  );
}

export default H4;
